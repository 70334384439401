:root {
  --white: #ffffff;
  --codere_green: #79c000;
  --grey: #333f48cc;
  --black: #000000;
}
/* Roboto font */
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body {
  background-color: #252a30;
}
html,
body {
  overflow-x: hidden !important;
  font-family: "Roboto";
}

::-webkit-scrollbar {
  display: none;
}

.color-green {
  color: #79c000;
}

/* Cookie Consent */
.cookie-content h3,
.cookie-content a {
  color: var(--codere_green);
}
.cookie-content {
  opacity: 0.9;
  flex: 0.75 0 300px !important;
  margin: 15px 0 !important;
}
.cookie-btn {
  background-color: var(--codere_green) !important;
  border-radius: 4px !important;
  font-weight: 600;
  width: 250px;
  transition: all 300ms ease;
  color: white !important;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 1.2rem;
}
.cookie-btn:hover {
  background-color: #79c000cd !important;
  transition: all 300ms ease;
}

.cookie-overlay {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
.cookie-container {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8) !important;
  flex-wrap: wrap !important;
  align-items: flex-end !important;
  justify-content: space-evenly !important;
}

/* stroke */
.header-group-left {
  display: flex;
  align-items: center;
  position: absolute;
  left: 30px;
}
.header-group-right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 30px;
}
.navbar-nav {
  margin-left: 7% !important;
}
.stroke .navbar-nav a,
.stroke .navbar-nav a:after,
.stroke .navbar-nav a:before {
  transition: all 0.5s;
}
.stroke .navbar-nav a {
  position: relative;
}
.stroke .navbar-nav a:after {
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  background: var(--white);
  height: 2px;
  border-radius: 5px;
}
.stroke .navbar-nav a:hover:after {
  width: 100%;
}
/* End Stroke */
.header_nav {
  background-color: #252a30;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar {
  height: 4rem;
  position: fixed;
  width: 100%;
  z-index: 6;
}
.nav-link {
  padding: initial;
}
.header_nav img {
  position: relative;
  width: 9.5rem;
  margin-right: 1vw;
  left: 0;
  cursor: pointer;
}
/* DropDown */
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle,
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  background-color: unset;
  border-color: unset;
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: unset;
}
.dropdown button {
  background: 0 0;
  border: unset;
  /* color: var(--black); */
}
.dropdown svg {
  width: 20px;
}

.dropdown-toggle::after {
  display: none;
}
.dropdown button:hover {
  background: 0 0;
  color: var(--white);
  border: unset;
}
.dropdown-menu[data-bs-popper] {
  margin-top: 0.4vw;
  left: -5vw;
}
.dropdown-menu {
  width: 15rem;
  background-color: #212529db;
  text-align: center;
  /* padding: 0.1vw 0.1vw 0 0.1vw; */
  margin: auto;
  /* border-radius: 0 0 1vw 1vw; */
}
.dropdown-item {
  margin: auto;
  color: var(--white);
  border-bottom: #ffffff73 0.5px solid;
  padding: 0.55rem 0rem;
  text-transform: capitalize;
  width: 80%;
}
.dropdown a:first-child {
  padding-top: 5px;
}
.dropdown a:last-child {
  border: unset;
  padding-bottom: 5px;
}
/* .dropdown-item a{} */
.dropdown-item:hover,
.dropdown-item:active {
  font-weight: 600;
  background-color: #e9ecef;
  color: #000;
}
.dropdown .dropdown-menu {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.9s;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
}
.dropdown.show .dropdown-menu {
  max-height: 25rem;
  opacity: 1;
}
/* End DropDown */

.acceder-button {
  background-color: #79c000;
  border: initial;
}

.acceder-button:hover,
.acceder-button:active {
  background-color: #79c000cc !important;
}

.registrate-button:active,
.registrate-button:focus {
  background-color: #0d6efdcc !important;
}

.acceder-button,
.registrate-button {
  border: unset;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  /* width: 110px; */
  padding: 5px 15px;
  font-size: 1.15rem;
}

.central-regis {
  border-radius: 30px;
  font-size: 1rem;
  padding: 17px 11px;
  margin: 0;
  margin-left: 0.5vw;
  font-weight: bold;
  max-height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(180deg, #0587f5, #0263b5);
   */
  background-color: #0056fe;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.central-regis:hover,
.central-regis:active,
.central-regis:focus {
  background-color: #0056fecc;
  /* background: linear-gradient(180deg, #0263b5, #0587f5); */
}
.slidebtn {
  position: relative;
  top: -10vw;
  left: 16vw;
  width: 18%;
  text-align: center;
}
.Participa::before {
  content: "Participa";
}
.Registrate::before {
  content: "Regístrate";
}
.Encuentranos::before {
  content: "Encuentranos";
}
.terms {
  margin-top: 0.6vw;
  color: var(--white);
}
.mob-tyc {
  line-height: 1.5;
}
.mob-tyc .tyc {
  margin: 0;
  text-align: center;
  text-decoration: underline;
  font-size: 3vw;
  line-height: 1;
}

.fs-7 {
  font-size: 0.75rem;
}

.tyc {
  color: var(--white);
  cursor: pointer;
  font-size: 0.85rem;
}
.tyc:visited {
  color: var(--white);
}
.tyc:hover {
  color: var(--codere_green);
  text-decoration: none;
}

#des-reg {
  position: absolute;
  z-index: 1;
  width: 18vw;
  top: 24.8vw;
  left: 16.2vw;
  text-align: center;
}
.central-regis {
  font-size: 1.7vw;
  border-radius: 8px;
  padding: 10px 0 10px 0;
  border: unset;
  z-index: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
}
.jugar-div {
  position: sticky;
  top: 15.75rem;
}
.jugar-regis {
  background-color: #c546d3;
  border: unset;
  float: right;
}

.jugar-regis:hover,
.jugar-regis:active,
.jugar-regis:focus {
  background-color: #c546d3aa;
}

.central-regis svg {
  margin-left: 0.5rem;
  width: 1.55rem;
  height: 1.55rem;
}
.central-regis:hover svg {
  animation: sds2 0.8s infinite;
}
/* END TEST */
.login_btn {
  margin: auto;
  text-align: center;
  background-color: var(--codere_green);
  border: var(--codere_green);
  width: 65%;
  margin-bottom: 5vw;
  padding: 1em;
  border-radius: 20px;
}
.form-control {
  width: 65%;
  text-align: center;
  font-size: 20px;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-color: var(--codere_green);
  margin: auto;
  padding: 0.7vw;
  border-radius: 20px;
}
.modal-content {
  /* background-color: rgb(148, 147, 147); */
  border-radius: 8px;
}
.terms-tyc {
  background-color: var(--white);
  color: var(--black);
  overflow-x: hidden;
  max-width: 450px;
}
.modal-body {
  overflow-x: hidden;
}
.terms-tyc .modal-body table {
  overflow-x: auto !important;
}
.terms-tyc .btn-close {
  position: absolute;
  right: 15px;
  top: 15px;
}
.terms-tyc .modal-title {
  margin: auto;
}
.terms-tyc .modal-title img {
  width: 75px;
}
.terms-tyc .modal-header,
.terms-tyc .modal-footer {
  border: unset;
}
.terms-tyc .test {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  width: 100%;
}
.terms-tyc ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.terms-tyc ol {
  font-size: 0.75rem;
  counter-reset: my-counter;
  list-style-type: none;
  padding-left: 0;
}
.terms-tyc ol > li {
  counter-increment: my-counter;
  padding-left: 25px;
  position: relative;
}
.terms-tyc ol > li::before {
  content: counter(my-counter) ". ";
  color: var(--black);
  position: absolute;
  left: 0;
}
.modal-header .btn-close {
  padding: 0;
  margin: 0;
}
.table > :not(caption) > * > * {
  margin-left: 1rem;
}
.terms-tyc .modal-body {
  padding: 0.25rem 1rem;
}
.terms-tyc .accept-btn {
  background-color: var(--codere_green);
  border: var(--codere_green);
  border-radius: 6px;
  width: 100%;
  font-size: 1.15rem;
  font-weight: 700;
}
/* .btn-close:hover {
  transform: rotate(360deg);
  transition: transform 600ms ease-out;
} */
.modal-header .btn-close {
  padding: 0;
  margin: 0;
}
#modal_app {
  text-align: center;
}
#carousel-section {
  position: relative;
  top: 4rem;
  min-height: 60vh;
}
/* Swiper */
.swiper-slide img {
  height: auto;
  max-width: 100%;
  width: 100%;
}
.swiper-pagination {
  bottom: 2rem !important;
}
.swiper-pagination-bullet-active {
  background-color: var(--codere_green) !important;
}
/* .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
} */
/*  */
/* GAMES */
#desktop_games {
  text-align: center;
  /* margin-top: 4vw; */
}
#desktop_games h2 {
  /* text-align: center; */
  color: var(--white);
  font-size: 1.4vw;
  color: #e8e5e5;
  font-weight: 700;
  margin-bottom: 1vw;
  letter-spacing: 1px;
}
.top_games_conatiner {
  display: inline-flex;
  grid-template-columns: auto auto 0;
  grid-gap: 3rem;
  /* padding: 2rem 0 4rem; */
}
.top_games_conatiner .topGame {
  margin: auto;
  width: 35vw;
}
.topGameImage,
.game_logo {
  transition: 0.3s;
}
.top_games_conatiner .topGame:hover .topGameImage {
  transform: scale(0.95);
}
.top_games_conatiner .card {
  background-color: #26363f;
  border-radius: 40px;
  height: 25vw;
  padding: 1rem;
  position: relative;
  overflow-x: hidden;
  border-radius: 16px;
}
.top-logo-1 {
  top: 6vw;
}
img.topGameImage {
  position: absolute;
  width: 35vw;
  top: 0;
  left: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  -webkit-box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.78);
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.78);
}
img.game_logo {
  margin: auto;
  width: 50%;
  position: relative;
  top: 3vw;
  z-index: 1;
}
.top_games_conatiner .topGame:nth-child(2) .game_logo {
  top: 5vw;
}
img.game_logo:hover {
  transform: scale(1.15);
}
.game_info {
  display: inline-flex;
  bottom: 1vw;
  position: absolute;
  text-align: left;
  border-left: 4px var(--white) solid;
  border-radius: 1px;
  color: var(--white);
  padding-left: 1vw;
  margin-left: 1vw;
}
.topGameTitle {
  font-size: 1.5vw;
  font-weight: 700;
  letter-spacing: -1px;
  width: 19vw;
}
/* Botttom Games */
.bottom_conatiner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 80%;
  margin: auto;
  cursor: pointer;
}
.bottom_conatiner h3 {
  margin-top: 6px;
  color: var(--white);
  font-size: 1.3vw;
}
.bottom-box .game img {
  width: 26rem;
  border-radius: 15px;
}
.bottom-box .logo img {
  height: 5vw;
}
.bottom_conatiner > div:nth-child(2) > div.bottom-box > div.logo {
  top: 0;
}
.bottom_conatiner :nth-child(3) .logo img {
  height: 10vw;
}
.bottom_conatiner .bottom-card {
  position: relative;
  min-width: 20vw;
  height: 27vw;
  box-shadow:
    inset 5px 5px 5px rgb(0 0 0 / 20%),
    inset -5px -5px 15px rgb(255 255 255 / 10%),
    5px 5px 15px rgb(0 0 0 / 30%),
    -5px -5px 15px rgb(255 255 255 / 10%);
  border-radius: 15px;
  margin: 25px 0;
  transition: 0.5s;
}
/* .bottom-content a {
  background: #2196f3;
} */
.bottom_conatiner .bottom-card .bottom-box {
  position: absolute;
  height: 25vw;
  top: -0.3vw;
  left: 15px;
  right: 15px;
  bottom: 0;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}
.bottom_conatiner .bottom-card:hover .bottom-box {
  /* transform: translateY(-80px); */
  transform: scale(0.9) translateY(-3vw);
}
.bottom_conatiner .bottom-card .bottom-box .bottom-content {
  padding: 10px;
  text-align: center;
}
.bottom_conatiner .bottom-card .bottom-box .bottom-content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}
.bottom_conatiner .bottom-card .bottom-box .bottom-content h3 {
  font-size: 1.8rem;
  color: var(--white);
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}
.bottom_conatiner > a:nth-child(2) .logo {
  top: 0;
}
.bottom-box .logo {
  position: absolute;
  /* top: 0;
  left: 0; */
}
.bottom_conatiner .bottom-card .bottom-box .bottom-content p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}
.bottom-content span {
  z-index: -1;
  position: relative;
  top: 21vw;
  display: inline-block;
  font-size: 1.6vw;
  padding: 6px 20px 6px;
  border-radius: 15px;
  text-decoration: none;
  color: var(--white);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
.bottom-game-title {
  position: relative;
  top: 21.5vw;
}
.bottom_conatiner .bottom-card .bottom-box .bottom-content a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: var(--white);
  color: var(--black);
}
.bottom-content .svg-inline--fa {
  top: 0;
  height: 0.8em;
  left: 5px;
}
#swipe-down {
  color: var(--white);
  top: -10vw;
  left: 48%;
  width: 30px;
  height: auto;
  z-index: 999;
  border-radius: 50vw;
  border: 5px var(--white) solid;
  padding: 12px;
  cursor: pointer;
  animation-name: upanddown;
  animation-duration: 1s;
  position: relative;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}
@keyframes upanddown {
  0% {
    transform: translateY(0.4rem);
  }
  50% {
    transform: translateY(-0.5rem);
  }
  100% {
    transform: translateY(0.4rem);
  }
}
/*  */

/* Content */
.content-container {
  margin: auto;
  color: var(--white);
  width: 90%;
}
.content-container h1,
.content-container h2,
.content-container h3,
.content-container a {
  color: var(--codere_green);
}

.apuestas {
  margin: auto;
  width: 23rem;
}
.apuestas .col {
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}
.left-col,
.middle-col,
.right-col {
  width: 30%;
  padding: 1.2em 1.2em 0 1.2em;
  border-radius: 25px;
  /* background-color: rgb(68, 68, 68); */
  margin: 1px;
  /* -webkit-box-shadow: 1px 2px 11px 0px rgba(121, 192, 0, 0.51);
  box-shadow: 1px 2px 11px 0px rgba(121, 192, 0, 0.51); */
}
/* Table */
.table {
  width: 90%;
  margin: auto;
  margin-bottom: 1rem;
}
.table > tbody {
  vertical-align: middle;
}
/* Table End  */
/* Tabs */
.nav-tabs {
  border-bottom: none;
}
.nav-tabs .nav-link {
  background: #252a30;
  color: var(--white);
  font-weight: 600;
  /* text-decoration: underline dashed var(--white) 1px; */
  border-right: var(--codere_green) 0.5px solid;
  border-left: var(--codere_green) 0.5px solid;
  border-radius: 0;
  /* border-top: var(--codere_green); */
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: unset;
  border-color: transparent;
  color: var(--white);
  /* border-top-color: var(--codere_green); */
  border-bottom-color: var(--codere_green);
  text-decoration: none;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-bottom-color: var(--codere_green);
  border-color: #252a30 #252a30 var(--codere_green) #252a30;
  border-radius: 8px;
  text-decoration: none;
}
.seo-questions {
  margin: auto;
  width: fit-content;
}
.tab-content > .active {
  text-align: left;
  margin: auto;
  width: 64%;
}
/* End Tabs */
/* Content Accordion */
#acc-section {
  width: 70%;
  margin: auto;
}
#acc-section h2 {
  font-size: 1.8rem;
}
.accordion-body {
  color: var(--white);
}
.accordion-body ul {
  padding-left: 1rem;
}
.content-accordion .accordion-button:not(.collapsed),
.content-accordion .accordion-button {
  color: var(--codere_green);
  background-color: unset;
}
.accordion-button:not(.collapsed),
.accordion-button {
  background-color: unset;
}
.accordion-header,
.accordion-item {
  background-color: unset;
}
.accordion-button:focus {
  border-color: unset;
  box-shadow: unset;
}
.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
.hamb-accordion {
  color: var(--white);
}
.hamb-accordion .accordion-item {
  border: unset;
}
.hamb-accordion .accordion-button:not(.collapsed),
.hamb-accordion .accordion-button {
  color: var(--white);
  font-size: 5vw;
  border-bottom: var(--white) 1px solid;
  border: unset;
  box-shadow: none;
}
.hamb-accordion .accordion-body {
  padding: 0;
}
.hamb-accordion .accordion-body li {
  padding-top: 0.25rem;
}
.hamb-accordion .accordion-body li a {
  width: 100%;
  display: block;
}

/* End Accordion */
/* End Content */
/* ------------------------ */
/* Footer */
hr {
  margin: auto;
  margin-top: 3vw;
  border-top: 4px solid var(--codere_green);
  width: 55%;
}
footer .social-media {
  margin-top: 10px;
}
.sponsor {
  text-align: center;
}
.sponsor img {
  width: 9rem;
  margin: 4px;
}
.feedback-hvr {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}
.feedback-hvr:hover {
  opacity: 0.7;
  transition: opacity 300ms ease-in-out;
}
.methods {
  gap: 20px;
}
.methods img {
  width: 6rem;
}
.social-media a {
  align-items: center;
  background-color: var(--white);
  border: 1px solid hsla(0, 0%, 100%, 0.3);
  border-radius: 50%;
  box-shadow: 0 0 5px 1px hsla(0, 0%, 100%, 0.71);
  color: var(--codere_green);
  display: flex;
  flex-wrap: nowrap;
  font-size: 25px;
  height: 46px;
  justify-content: center;
  line-height: 45px;
  text-decoration: none;
  width: 46px;
}
.social-media a svg {
  width: 2rem;
}
.list-inline li {
  display: inline-flex;
  padding-left: 1vw;
}
.list-inline li:nth-child(1) {
  padding-left: 0;
}
.whatsapp:hover .fa-whatsapp {
  color: var(--codere_green);
}
footer .footer-info-single .title {
  color: var(--codere_green);
  text-transform: uppercase;
  font-size: 16px;
  border-left: 4px solid var(--codere_green);
  padding-left: 5px;
  font-weight: 500;
}
footer .footer-info-single ul li a {
  display: block;
  color: var(--white);
  padding: 2px 0;
  text-decoration: none;
  transition: 150ms color ease-in-out;
}
footer .footer-info-single ul li a:hover {
  color: var(--codere_green);
  transition: 150ms color ease-in-out;
}
footer .footer-info-single p {
  font-size: 13px;
  line-height: 20px;
  color: #aaa;
}
#legals {
  margin: auto;
  display: flex;

  font-size: 0.8rem;
  line-height: 1.25rem;
  text-align: center;
  color: #a1a4a6;
  flex-direction: column;
}
#legals img {
  position: relative;
  width: 2vw;
  /* margin-left: 2vw; */
  margin-right: 1vw;
}
.juegas-banners {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 35px;
  align-items: center;
  background-color: var(--black);
  padding: 10px 0;
}
.juegas-banners img {
  height: 3rem;
  padding: 0.3rem;
}
/* End Footer */

/* Mobile */
@media only screen and (max-width: 767px) {
  /* Cookie */
  .cookie-btn {
    width: 92vw;
    margin: 0 !important;
  }
  /* End Cookie */
  .content-container {
    width: 95%;
    margin: 1rem auto;
    padding: 3.5vw;
  }
  .content-container .row {
    margin: auto;
  }
  .left-col,
  .middle-col,
  .right-col {
    padding: 0;
  }
  .modal-dialog {
    margin: auto;
    width: 84%;
  }
  .form-control {
    width: 85%;
  }

  /* Buttons Mobile*/
  .acceder-button,
  .registrate-button {
    font-size: 3.5vw;
    width: 21vw;
    display: flex;
    justify-content: center;
  }
  /* End Buttons Mobile */
  .header_nav img {
    width: 33vw;
    margin-right: 7.5vw;
    height: auto;
    left: -1vw;
  }
  .header_nav {
    width: 100%;
  }
  #header_buttons {
    display: flex;
    gap: 0.5rem;
  }
  #father {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    float: right;
    align-items: center;
    margin-left: 13vw;
  }
  #outer-container > div {
    margin-left: -13vw;
  }
  .bm-item-list svg {
    height: 1.6em !important;
    margin-right: 0.5rem;
    min-width: 36px;
  }
  .bm-item-list a {
    align-items: center;
    color: var(--white);
    display: flex !important;
    font-size: 1.1rem;
    justify-content: space-between;
    padding: 3vw 1vw 1vw;
    text-decoration: none;
  }

  .bm-item-list .accordion a {
    padding: 1vw 1vw 1vw;
  }
  /* Hamburger */
  /* Position and sizing of burger button */
  nav ul,
  nav li {
    list-style: none;
  }
  .bm-burger-button {
    height: 20px;
    left: 3vw;
    position: absolute;
    top: 25px;
    width: 28px;
  }
  .bm-menu-wrap,
  .bm-overlay {
    top: 0;
  }
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    opacity: 1 !important;
    background: #79c000;
    border-radius: 60px;
  }

  .casino-burger .bm-burger-bars {
    background: #c546d3;
  }
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 12px;
    width: 12px;
  }
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  /* General sidebar styles */
  .bm-menu {
    background: rgba(50, 63, 72);
    padding: 0 0.5em 0 0.5em;
    font-size: 1.15em;
  }
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: rgba(50, 63, 72);
  }
  #elastic,
  #outer-container .bm-overlay {
    height: 100% !important;
    top: 4rem !important;
  }
  #elastic .accordion-body {
    min-height: 75vh;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding-top: 0.8em;
    overflow-y: auto;
    width: 182%;
  }
  .bm-menu .fa-angle-right {
    float: right;
  }
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  .bm-item:focus-visible,
  .bm-item:focus {
    outline: unset;
  }
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.7) !important;
  }
  .bm-burger-button > span > span:nth-child(1) {
    width: 90%;
    transition: transform 100ms;
  }
  .bm-burger-button > span > span:nth-child(2) {
    width: 90%;
    transition: transform 100ms;
  }
  .bm-burger-button > span > span:nth-child(3) {
    width: 70%;
    transition: transform 100ms;
  }
  .open > span > span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: transform 700ms;
  }
  .open > span > span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transition: transform 400ms;
  }
  .open > span > span:nth-child(1) {
    top: 2vw !important;
    width: 80%;
    left: 5%;
  }
  .open > span > span:nth-child(2) {
    /* top: 0vw !important; */
    width: 0%;
    /* left: 5%; */
  }
  .open > span > span:nth-child(3) {
    top: 2vw !important;
    width: 80%;
    left: 5%;
  }
  /* End Hamburger */
  .navbar-expand .navbar-nav .nav-link {
    font-size: 1.2vw;
    font-weight: bold;
    text-align: center;
  }
  #carousel-section {
    top: 66px;
    min-height: 70vh;
  }
  /* Games */
  .top_games_conatiner {
    display: block;
    /* grid-template-columns: auto auto 0; */
    /* grid-gap: 3rem; */
    padding: 0.5rem;
  }
  .top_games_conatiner .topGame {
    width: auto;
  }
  .top_games_conatiner .card {
    height: 65vw;
    margin-bottom: 1vw;
  }
  img.topGameImage {
    width: 100%;
  }
  .topGameTitle {
    font-size: 4.5vw;
    width: 60vw;
  }
  .jugar-regis {
    width: 22vw;
    /* float: right; */
    font-size: 3.2vw;
    position: relative;
  }
  .swiper-pagination {
    bottom: 0 !important;
  }
  .swiper-pagination-bullet {
    width: var(
      --swiper-pagination-bullet-width,
      var(--swiper-pagination-bullet-size, 10px)
    ) !important;
    height: var(
      --swiper-pagination-bullet-height,
      var(--swiper-pagination-bullet-size, 10px)
    ) !important;
  }
  /* End Games  */
  /* Content */
  .content-container .col-sm-4 {
    margin: 0;
  }
  .left-col,
  .middle-col,
  .right-col {
    width: auto;
  }
  .apuestas {
    width: auto;
    margin-bottom: 3vw;
  }
  .apuestas .col {
    padding-left: 5vw;
  }
  /* End Content */
  /* ------------------- */
  /*  Sticky Footer */
  .sticky-footer-contatiner {
    text-align: center;
    position: fixed;
    display: flex;
    bottom: 0vw;
    background-color: #252a30;
    height: 15vw;
    width: 100%;
    z-index: 6;
    padding: 2.5vw;
    flex-direction: row;
    justify-content: space-between;
  }
  .sticky-item {
    display: flex;
    font-size: 4vw;
    align-items: center;
    text-decoration: none;
    color: var(--white);
    font-weight: 700;
    width: 33vw;
    background: var(--codere_green);
    border-radius: 19px;
    margin-left: 1vw;
    box-shadow: 0 0 12px 1px rgb(0 0 0 / 75%);
    border: none;
    justify-content: space-around;
  }
  .sticky-item:hover {
    color: var(--white);
  }

  /* End Sticky Footer */
  /* ------------------- */
  /* Footer */
  footer .row {
    margin-left: 0.2vw;
  }
  footer {
    margin-bottom: 20vw;
  }
  /* social */
  .whatsapp svg {
    width: 8vw !important;
  }
  .list-inline li {
    padding-left: 5vw;
  }
  .list-inline li:nth-child(1) {
    padding-left: 0;
  }
  .sponsor {
    padding-top: 0;
    padding-bottom: 3rem;
  }
  .methods img {
    max-width: 5.5rem;
  }
  #legals {
    display: flex;
    width: 95%;
    font-size: 0.45rem;
    line-height: 12px;
    text-align: center;
  }
  #legals img {
    width: 10vw;
    margin-left: 2vw;
    margin-right: 3vw;
  }
  .juegas-banners {
    gap: 10px;
    width: 100%;
  }
  .juegas-banners img {
    max-height: 10vw;
  }
  /* End Footer */
  #acc-section {
    width: 100%;
  }
}
/* Mendoza-Caba popup  element */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.popup-content {
  background-color: #1a1d22;
  border-radius: 8px;
  max-width: 40vw;
  padding: 2vw;
  position: relative;
  width: 100%;
  text-align: center;
}
.popup-content > h2 {
  border: 0;
  color: var(--white);
  padding-bottom: 0.9rem;
  text-align: center;
}
.popUpBtn {
  align-items: center;
  display: flex;
  justify-content: center;
}
.popup-content a {
  margin: 5px 2vw;
}
.button-link {
  align-items: center;
  background-color: var(--codere_green);
  border-radius: 4px;
  color: var(--black);
  display: flex;
  font-size: 1.5rem;
  font-weight: 500;
  justify-content: center;
  min-width: 11vw;
  padding: 0.4vw 2vw;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
}
.button-link:hover {
  background-color: #3e8e41;
  color: var(--black);
}
.popup-content button {
  position: absolute;
  right: 2vw;
  top: 1vw;
}
.close-button {
  background: transparent;
  border: none;
  cursor: pointer;
  height: 30px;
  position: relative;
  width: 30px;
}
.close-button:before,
.close-button:after {
  background-color: var(--codere_green);
  content: "";
  height: 20px;
  position: absolute;
  top: 5px;
  left: 14px;
  width: 2px;
  transform-origin: center;
}
.close-button:before {
  transform: rotate(45deg);
}
.close-button:after {
  transform: rotate(-45deg);
}
.tagline,
.coderePopupH2 {
  color: var(--white);
}
.popupFooter {
  color: var(--white);
}
.codereAdditionalText {
  color: var(--white);
}
.footerLogoRTA {
  max-width: 9rem;
  height: 3rem;
}

.regionText {
  color: var(--white);
  margin-bottom: 0;
  font-size: 0.8rem;
  margin-top: 0.25rem;
}
.titleAreaPopup {
  margin-bottom: 4rem;
}
/* Mobile */
@media only screen and (max-width: 767px) {
  .popup-content {
    max-width: 90%;
    padding: 3rem 0 3rem 0;
  }
  .button-link {
    font-size: 1.4rem;
    padding: 0.9vw 4vw;
    width: 80%;
  }
  .popUpBtn {
    margin: 0.5rem 0 0.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}
.inicioDesclGamesLine {
  color: var(--white);
}
.inicioDesclGamesLineFSize {
  font-size: 0.8rem;
}
