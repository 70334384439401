.cas-seo h1,
.cas-seo h2,
.cas-seo h3 {
  font-weight: 400;
  color: #c546d3;
}
.cas-seo .accordion-button,
.cas-seo.accordion-button:not(.collapsed) {
  color: #c546d3 !important;
}

.cas-seo .content-table i {
  background: #c546d3;
}

.subtitle {
  font-weight: 400;
  color: #79c000;
  border-radius: 0;
  padding-left: 0.75rem;
}

.seo-nav-active {
  color: #79c000;
  filter: brightness(0) saturate(100%) invert(77%) sepia(60%) saturate(4287%)
    hue-rotate(40deg) brightness(96%) contrast(101%);
}

.c-vr {
  height: 70%;
  margin-top: -5px;
  border-left: 0.5px solid #444444a1;
}

.e-nav span {
  font-weight: 400;
  font-size: 0.75rem;
}

.underline {
  width: 100%;
  height: 3px;
}

.seo-nav-active .underline {
  background-color: #79c000;
}

.c-seo-nav-active {
  filter: brightness(0) saturate(100%) invert(50%) sepia(39%) saturate(5243%)
    hue-rotate(261deg) brightness(83%) contrast(99%);
}

.c-seo-nav-active .underline {
  background-color: #c546d3;
}

.c-seo-nav-active span {
  color: #c546d3;
}

.e-nav {
  justify-content: center;
  height: 8.5rem;
  padding: 4.7rem 0 0 0;
}

.e-nav a {
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: center;
  transition: opacity 75ms ease-in-out;
}

.e-nav .hoverable:hover,
.e-nav .hoverable:hover span {
  opacity: 0.5;
  transition: opacity 75ms ease-in-out;
}

.seo-nav-tab {
  min-width: 80px;
}

h5.colored {
  border-bottom: 3px solid #79c000;
  padding-bottom: 5px;
}

.e-nav .c-link:hover {
  filter: brightness(0) saturate(100%) invert(50%) sepia(39%) saturate(5243%)
    hue-rotate(261deg) brightness(83%) contrast(99%);
}
.e-nav .d-link:hover {
  filter: invert(55%) sepia(103%) saturate(1266%) hue-rotate(62deg)
    brightness(94%) contrast(77%);
}

.event h1,
.cas-seo h1 {
  text-align: center;
}

.event h1,
.event h2,
.event h3 {
  line-height: 1.1;
  color: var(--codere_green);
}
ol > li {
  color: #fff;
}
#eventos ul,
.event ul {
  color: #fff;
  padding-left: 0;
}

#eventos p,
.event p {
  color: #fff;
  font-size: 1.25rem;
  text-align: left;
}

.ver_btn {
  background-color: unset;
  border: 1px solid #79c000;
  font-weight: 400;
  border-radius: 15px;
  font-size: 1.4rem;
  padding: 0.75rem 4rem 0.75rem 4rem;
  float: left;
  transition-duration: 0.35s;
}

#eventos a h2 {
  font-size: 1.1vw;
  font-weight: 700;
  color: #79c000;
  position: relative;
  text-align: center;
}

.ver_btn:hover,
.ver_btn:focus {
  background-color: unset;
  border: none;
  /* box-shadow: inset 13em 0 0 0 #79c000; */
  box-shadow: inset 13em 0 0 0 #548801;
}

/* Card */

.eventos-container {
  max-width: 75%;
  display: flex;
  text-align: center;
}

.eventos-container .row > * {
  padding: 0;
}

.eventos_card {
  background-color: #323f47;
  margin: 5px;
  min-height: 200px;
  padding: 1rem 0;
  border-radius: 0.5rem;
}

.card-header {
  padding: 0;
  background-color: unset !important;
  border-bottom: unset !important;
  text-transform: uppercase;
}

.eventos-container img {
  max-width: 100%;
  transition: all 0.5s;
}

.eventos-container img {
  filter: grayscale(55%);
  transition: all 0.5s;
}

.eventos-container :hover img {
  filter: grayscale(0);
}

.card-footer {
  padding: 0.5rem 5px;
  border-top: none;
}

.event .accordion {
  margin: 2rem 0 3rem 0;
}

.event .accordion-button {
  /* color: #fff; */
  font-weight: 600;
  font-size: 1.15rem;
}

.event .accordion-item {
  color: #fff;
}

.scroll .fa-chevron-up {
  padding: 0.7rem 0.3rem 0.7rem 0.3rem;
}

/* Casino */
.casino-row {
  --bs-gutter-y: 1.5rem;
  justify-content: center;
  margin: 1rem 0;
}
.cas-game-wrapper {
  box-shadow:
    -10px 0 10px -7px #000,
    10px 0 10px -7px #000,
    5px 5px 15px 5px transparent;
}

.top-bg-seo {
  padding-bottom: 17.5%;
}

.cas-game-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cas-game-wrapper .hvr {
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: all 300ms ease-in-out;
  transform: scale(0.65);
}

.hvr {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hvr p {
  white-space: nowrap;
  text-align: center;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 250px;
  font-size: 1.25rem;
  font-weight: 700;
  display: none;
}

.hvr img {
  max-width: 104px;
  /* transition: opacity 0.5s; */
  /* transition: opacity 0.3s ease-in-out; */
}

.cas-game-wrapper:hover .hvr {
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.cas-reg-btn {
  display: flex;
  justify-content: center;
  margin: 2rem auto 0;
  border: none;
  border-radius: 4px;
  font-size: 1.25rem;
  font-weight: 700;
  padding: 0.65rem 4rem;
  text-align: center;
  max-width: 180px;
}

.shining {
  -webkit-mask-image: linear-gradient(
    45deg,
    #000 25%,
    rgba(0, 0, 0, 0.2) 50%,
    #000 75%
  );
  mask-image: linear-gradient(
    45deg,
    #000 25%,
    rgba(0, 0, 0, 0.1) 50%,
    #000 75%
  );
  -webkit-mask-size: 800%;
  mask-size: 800%;
  -webkit-mask-position: 0;
  mask-position: 0;
}

.shining:hover {
  /* transition: mask-position 2s ease, -webkit-mask-position 2s ease; */
  -webkit-mask-position: 100%;
  mask-position: 100%;
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .seo-nav-tab {
    min-width: 70px;
    height: 65px;
  }

  .e-nav .hoverable:hover,
  .e-nav .hoverable:hover span {
    opacity: 1;
  }

  .e-nav {
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: space-evenly;
    white-space: nowrap;
    height: 31vw;
    padding: 17vw 0 0 0;
  }

  .c-vr {
    height: 13vw;
    border: 1px solid #4444446a;
  }

  .e-nav img {
    width: 30px;
    height: 30px;
  }
  #eventos h1 {
    padding: 0 0.25rem 0 0.25rem;
    /* margin-bottom: 1.75rem; */
  }

  #eventos a h2 {
    font-size: 1rem;
  }

  /* Card */
  .eventos-container {
    max-width: 100%;
    display: block;
  }

  .eventos-container h2,
  .eventos-container p,
  .ver_btn {
    text-align: center;
    float: none;
  }

  .eventos_card {
    width: 100%;
    margin: auto;
    padding: 0.35rem 0;
  }

  .eventos-container .row > * {
    padding: 0.25rem;
  }

  .eventos_card .btn {
    padding: 0.475rem 0.1rem;
    font-size: 0.6rem;
  }

  .card-body {
    padding: 0.25rem;
  }

  .event h1 {
    font-size: 1.45rem;
  }
  .event h2 {
    font-size: 1.3rem;
  }

  .event .accordion {
    width: 100%;
  }

  /* Casino */
  .casino-row {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0.5rem;
    margin: 0;
  }

  .top-bg-seo {
    padding-bottom: 50%;
  }
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  /* CSS */
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  /* CSS */
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  /* CSS */
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* CSS */
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  /* CSS */
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 321px) and (max-width: 480px) {
  .e-nav {
    height: 35vw;
    padding: 20vw 0 0 0;
  }
  .cas-reg-btn {
    max-width: 42.5vw;
    margin: 6vw auto;
    padding: 2vw 0;
  }
}

@media (max-width: 320px) {
  .e-nav {
    height: 41vw;
    padding: 23vw 0 0 0;
  }
  .e-nav a svg {
    font-size: 1.75rem !important;
  }
}
