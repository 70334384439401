#game_svg {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  animation-name: sideToSide;
  margin-left: 0.2vw;
  position: relative;
  top: 1px;
}

@keyframes sideToSide {
  0% {
    transform: translateX(0.2rem);
  }

  50% {
    transform: translateX(-0.1rem);
  }

  100% {
    transform: translateX(0.2rem);
  }
}

#desktop_games {
  text-align: center;
  padding-top: 90px;
}

#desktop_games h2 {
  color: #fff;
  font-size: 1.4vw;
  color: #e8e5e5;
  font-weight: 700;
  margin-bottom: 1vw;
  letter-spacing: 1px;
  margin-top: 1vw;
}

.top_games_conatiner {
  display: inline-flex;
  grid-template-columns: auto auto 0;
  grid-gap: 3rem;
  padding: 2rem 0 4rem;
}

.top_games_conatiner .topGame {
  margin: auto;
  width: 35vw;
}

.topGameImage,
.game_logo {
  transition: 0.3s;
}

.top_games_conatiner .topGame:hover .topGameImage {
  transform: scale(0.95);
}

.top_games_conatiner .card {
  background-color: #26363f;
  border-radius: 40px;
  height: 25vw;
  /* padding: 1rem; */
  position: relative;
  overflow-x: hidden;
  border-radius: 16px;
}

img.topGameImage {
  position: absolute;
  width: 35vw;
  top: 0;
  left: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  -webkit-box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.78);
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.78);
}

img.game_logo {
  position: absolute;
  z-index: 1;
  margin: auto;
}

.top-logo-2 {
  bottom: 0;
}

.top_games_conatiner .topGame .game_logo {
  /* top: 1vw; */
  left: 0;
  right: 0;
}

img.game_logo:hover {
  transform: scale(1.15);
}

.game_info {
  bottom: 0.5vw;
  position: absolute;
  text-align: left;
  border-left: 4px #fff solid;
  border-radius: 1px;
  color: #fff;
  padding-left: 1vw;
  margin-left: 1vw;
}

.topGameTitle {
  font-size: 1.65vw;
  font-weight: 700;
  margin: 0;
}

/* Botttom Games */
.bottom_conatiner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 80%;
  margin: auto;
  cursor: pointer;
}

.bottom_conatiner h3 {
  margin-top: 6px;
  color: #fff;
  font-size: 1.3vw;
}

.bottom_box .game img {
  width: 22vw;
  border-radius: 15px;
}
.bottom_box .logo img {
  max-width: 85%;
}

.regis {
  width: 65%;
  margin: 0;
  border-radius: 4px;
  font-weight: 600;
  display: block;
  height: 100%;
  background-color: #c546d3;
}

.bottom_conatiner .bottom_card {
  position: relative;
  min-width: 20vw;
  height: 27vw;
  box-shadow:
    inset 5px 5px 5px rgb(0 0 0 / 20%),
    inset -5px -5px 15px rgb(255 255 255 / 10%),
    5px 5px 15px rgb(0 0 0 / 30%),
    -5px -5px 15px rgb(255 255 255 / 10%);
  border-radius: 15px;
  margin: 25px 0;
  transition: 0.5s;
}

/* .bottom_content a {
    background: #2196f3;
  } */

.bottom_conatiner .bottom_card .bottom_box {
  position: absolute;
  height: 25vw;
  top: -0.3vw;
  left: 15px;
  right: 15px;
  bottom: 0;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

.bottom_conatiner .bottom_card:hover .bottom_box {
  transform: scale(0.9) translateY(-3vw);
}

.bottom_conatiner .bottom_card .bottom_box .bottom_content {
  padding: 10px;
  text-align: center;
}

.bottom_conatiner .bottom_card .bottom_box .bottom_content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

.bottom_conatiner .bottom_card .bottom_box .bottom_content h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

.bottom_box .logo {
  position: absolute;
  z-index: 1;
}

.bottom_conatiner .bottom_card .bottom_box .bottom_content p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

.bottom_content span {
  text-align: center;
  position: relative;
  width: 65%;
  top: 21.5vw;
  color: white;
  font-size: 1.2vw;
  background-color: #c546d3;
  padding: 6px 23px 6px 25px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  z-index: -1;
}

.bottom_game_title {
  position: relative;
  top: 22.5vw;
}
.bottom_game_title h3 {
  overflow: hidden;
  width: 85%;
  font-size: 1.1rem;
  margin: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bottom_conatiner .bottom_card .bottom_box .bottom_content a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}

.jugar_div {
  position: absolute;
  bottom: 12px;
  right: 10px;
  width: 100%;
}

.row {
  justify-content: center;
}

/* TEST */

.shiny {
  overflow: hidden;
}

.shiny::before {
  animation-name: slide;
  content: "";
  position: absolute;
  top: 0;
  left: -10px;
  width: 6vw;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  transform: skewX(155deg);
  /* transform: scale(1.2); */
  /* animation-name: slide; */
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  /* animation-delay: 2.2s; */
  animation-iteration-count: infinite;
  animation-direction: normal;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.03) 20%,
    rgba(176, 209, 240, 0.445) 100%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(61, 86, 201, 0.1) 30%
  );
  z-index: 1;
}
@keyframes slide {
  0% {
    left: -20vw;
    top: 0;
  }
  100% {
    left: 30vw;
    top: 0;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .bottom_game_title {
    position: relative;
    top: 22vw;
  }
}
